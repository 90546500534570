<template>
  <div class="container">
    <div class="column form-wrap is-4">
      <div class="box" id="sign-up">
        <div class="iulaanu-logo m-4 has-text-centered">
          <img
            src="../../assets/logo.png"
            style="height: 36px"
            alt="cst-logo"
          />
        </div>
        <!-- <b-notification type="is-info is-light" :closable="false">
          Important! you will receieve an email with the link to activate your
          login. you can login after activating your account.
        </b-notification> -->
        <b-field label="">
          <b-input
            v-model="email"
            placeholder="Email"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field label="">
          <b-input
            v-model="phone"
            placeholder="Phone"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field label="">
          <b-input
            v-model="firstName"
            placeholder="First Name"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field label="">
          <b-input
            v-model="lastName"
            placeholder="Last Name"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field>
          <b-input
            type="password"
            v-model="password"
            placeholder="Password"
            password-reveal
            :readonly="isLoading"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-input
            type="password"
            v-model="re_password"
            placeholder="Password confirm"
            password-reveal
            :readonly="isLoading"
          >
          </b-input>
        </b-field>
        <div class="mt-1 has-text-left">
          Already have an account? <a @click.prevent="backtoLogin">Login</a>
        </div>
        <div class="field mt-5">
          <div class="control">
            <b-button
              type="is-primary is-fullwidth mb-3"
              @click.prevent="alertandValidate"
              :loading="isLoading"
            >
              Sign Up</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Signup",
  data() {
    return {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      password: "",
      re_password: "",
      url: "/auth/users/",
      error: false,
      errorMessage: "",
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    backtoLogin() {
      this.$router.push({
        name: "UserLogin",
      });
    },
    async performSignup() {
      let signupForm = new FormData();
      signupForm.append("email", this.email);
      signupForm.append("phone", this.phone);
      signupForm.append("first_name", this.firstName);
      signupForm.append("last_name", this.lastName);
      signupForm.append("password", this.password);

      await axios
        .post(this.url, signupForm)
        .then((response) => {
          if (response.status === 201) {
            this.$buefy.toast.open({
              message:
                "Sucessfully registered. Please follow the link in the email to activate your account",
              type: "is-success",
              queue: false,
              duration: 5000,
            });
            this.nextStep();
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again.";
            this.$buefy.toast.open({
              message: this.errorMessage,
              type: "is-danger",
              queue: false,
              duration: 3000,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data.email) {
              this.error = true;
              this.errorMessage = error.response.data.email;
              this.$buefy.toast.open({
                message: this.errorMessage,
                type: "is-danger",
                queue: false,
                duration: 3000,
              });
            } else if (error.response.data.password) {
              this.error = true;
              this.errorMessage = error.response.data.password;
              let errors = error.response.data.password;
              errors.forEach((error) => {
                this.$buefy.toast.open({
                  message: error,
                  type: "is-danger",
                  queue: true,
                  duration: 3000,
                });
              });
            } else if (error.response.data.first_name) {
              this.error = true;
              this.errorMessage = error.response.data.first_name;
              this.$buefy.toast.open({
                message: this.errorMessage,
                type: "is-danger",
                queue: false,
                duration: 3000,
              });
            } else if (error.response.data.last_name) {
              this.error = true;
              this.errorMessage = error.response.data.last_name;
              this.$buefy.toast.open({
                message: this.errorMessage,
                type: "is-danger",
                queue: false,
                duration: 3000,
              });
            } else {
              this.error = true;
              this.errorMessage = "Something went wrong. Please try again";
              this.$buefy.toast.open({
                message: this.errorMessage,
                type: "is-danger",
                queue: false,
                duration: 3000,
              });
            }
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again";
            this.$buefy.toast.open({
              message: this.errorMessage,
              type: "is-danger",
              queue: false,
              duration: 3000,
            });
          }
        });
    },
    formValid() {
      return (
        this.email &&
        this.phone &&
        this.firstName &&
        this.lastName &&
        this.password &&
        this.re_password &&
        this.password === this.re_password
      );
    },
    nextStep() {
      this.$router.push({ name: "UserLogin" });
    },
    resetError() {
      this.error = false;
      this.errorMessage = "";
    },
    submitForm() {
      this.$store.dispatch("setLoading", true);
      this.resetError();
      this.performSignup().then(() => {
        setTimeout(() => {
          this.$store.dispatch("setLoading", false);
        }, 1000);
      });
    },
    alertandValidate() {
      if (this.formValid()) {
        // buefy alert
        this.$buefy.dialog.alert({
          title: "Important!",
          message:
            "You will receive an email with the account activation link. Please <b> activate and login </b> to start using your account!",
          confirmText: "Create Account",
          onConfirm: () => this.submitForm(),
        });
      } else {
        this.error = true;
        if (this.password !== this.re_password) {
          this.errorMessage = "Passwords do not match.";
        } else {
          this.errorMessage = "Please fill out all fields.";
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.form-wrap {
  margin: 0 auto;
  margin-top: 5%;
}
</style>
